<script>
import {SimpleBar} from "simplebar-vue3";
import {mapGetters} from "vuex";
import router from "../router";
import _ from "lodash";

/**
 * Topbar component
 */
export default {

    computed: {
        ...mapGetters({
            locale: 'trans/locale',
            authenticated: 'user/authenticated',
            notifications: 'notification/list',
            notificationLoading: 'notification/loading',
            unread: 'notification/unread',
            allLoaded: 'notification/allLoaded',
            allRead: 'notification/allRead'
        }),

        letter: function () {
            return this.authenticated && this.authenticated.firstname ? this.authenticated.firstname[0] : '?'
        },

        language: function () {
            return this.languages.find(lang => lang.language === this.locale)
        },
    },

    data() {
        return {
            languages: [
                {
                    flag: require("@/assets/images/flags/us.jpg"),
                    language: "en",
                    title: "English",
                },
                {
                    flag: require("@/assets/images/flags/hungary.jpg"),
                    language: "hu",
                    title: "Hungarian",
                }
            ],
            text: null,
            flag: null,
            value: null,
            tempSearch: ''
        };
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        layoutscroll: {
            type: Boolean,
            required: true,
        },
        pagetitle: {
            type: String,
            required: true,
        },
    },
    mounted() {
    },
    methods: {
        search: function (event) {
            const text = event.target.value;
            this.tempSearch = text;
            _.debounce( (text) => {
                if (this.tempSearch === text) {
                    this.$router.push(this.$t('routes./partners/all') + `?type=all&with[0]=users&sorting[column]=name&sorting[desc]=false&search=${text}&page=1&per_page=10`)
                    this.$refs.search.click();
                }
            }, 700)(text);
        },
        router() {
            return router
        },
        selectLocale: function (locale) {
            this.$store.dispatch('trans/locale', locale)
        },
        toggleRightSidebar() {
            this.$parent.toggleRightSidebar();
        },
        logout() {
            if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                this.authLogout().then(() => {
                    this.$router.push(
                        this.$route.query.redirectFrom || {
                            name: "login",
                        }
                    );
                });
            } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
                this.authFakeLogout().then(() => {
                    this.$router.push(
                        this.$route.query.redirectFrom || {
                            name: "login",
                        }
                    );
                });
            }
        },
        toggleMenu() {
            this.$parent.toggleMenu();
        },
        markAsRead: function (notification) {
            if (!notification.read_at) {
                this.$store.dispatch('notification/markAsRead', notification.id);
            }
        },
        markAsReadAll: function () {
            this.$store.dispatch('notification/markAsReadAll')
        },
        more: function () {
            this.$store.dispatch('notification/fetch')
        },
        closeNotiList: function () {
            this.$refs["noti-btn"].classList.remove("show")
            this.$refs["noti-list"].classList.remove("show")
        },
    },
    components: {
        SimpleBar,
    },
    created() {
        this.$i18n.locale = this.locale
    },
    watch: {
        type: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case "dark":
                            // console.log('dark');
                            document.body.setAttribute("data-topbar", "dark");
                            break;
                        case "light":
                            document.body.setAttribute("data-topbar", "light");
                            document.body.removeAttribute("data-layout-size", "boxed");
                            break;
                        default:
                            document.body.setAttribute("data-topbar", "dark");
                            break;
                    }
                }
            },
        },
        layoutscroll: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    switch (newVal) {
                        case false:
                            document.body.setAttribute("data-layout-scrollable", "false");
                            break;
                        case true:
                            document.body.setAttribute("data-layout-scrollable", "true");
                            break;
                        default:
                            document.body.setAttribute("data-layout-scrollable", "false");
                            break;
                    }
                }
            },
        },
    },
};
</script>

<template>
    <header id="page-topbar" class="isvertical-topbar">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <router-link class="logo logo-dark" :to="$t('routes./profiles/:id(\\d+)', {id: authenticated?.id}).rmValidation()">
            <span class="logo-sm">
              <img alt="" height="22" src="@/assets/images/logo-dark-sm.png"/>
            </span>
                        <span class="logo-lg">
              <img alt="" height="22" src="@/assets/images/logo-dark-sm.png"/>
            </span>
                    </router-link>

                    <router-link class="logo logo-light" :to="$t('routes./profiles/:id(\\d+)', {id: authenticated?.id}).rmValidation()">
            <span class="logo-lg">
              <img alt="" height="22" src="@/assets/images/logo-light.png"/>
            </span>
                        <span class="logo-sm">
              <img alt="" height="22" src="@/assets/images/logo-light-sm.png"/>
            </span>
                    </router-link>
                </div>

                <button
                        class="
            btn btn-sm
            px-3
            font-size-16
            header-item
            vertical-menu-btn
            topnav-hamburger
          "
                        type="button"
                        @click="toggleMenu"
                >
          <span class="hamburger-icon open">
            <span></span>
            <span></span>
            <span></span>
          </span>
                </button>

                <div class="d-none d-sm-block ms-3 align-self-center">
                    <h4 class="page-title">
                        <a @click="router().go(-1)" class="cursor-pointer me-2">
                            <i class="fas fa-long-arrow-alt-left" :title="$t('base.back').ucFirst()"></i>
                        </a>
                        {{ pagetitle }}
                    </h4>
                </div>
            </div>

            <div class="d-flex">
                <div class="dropdown">
                    <button
                            ref="search"
                            aria-expanded="false"
                            aria-haspopup="true"
                            class="btn header-item"
                            data-bs-toggle="dropdown"
                            type="button"
                    >
                        <eva-icon class="icon-sm" name="search-outline"></eva-icon>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end dropdown-menu-md p-0">
                        <form class="p-2">
                            <div class="search-box">
                                <div class="position-relative">
                                    <input
                                            class="form-control bg-light border-0"
                                            :placeholder="$t('base.search_placeholder')"
                                            type="text"
                                            @input="search"
                                    />
                                    <eva-icon
                                            class="search-icon"
                                            name="search-outline"
                                            style="top: 50%"
                                    ></eva-icon>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="dropdown d-inline-block language-switch">
                    <button
                            aria-expanded="false"
                            aria-haspopup="true"
                            class="btn header-item"
                            data-bs-toggle="dropdown"
                            type="button"
                    >
                        <img
                                alt="Header Language"
                                class="header-lang-img"
                                height="16"
                                :src="language.flag"
                        />
                    </button>
                    <div class="dropdown-menu dropdown-menu-end">
                        <!-- item-->
                        <a
                                class="dropdown-item notify-item language"
                                data-lang="eng"
                                href="javascript:void(0);"
                                @click="selectLocale('en')"
                        >
                            <img
                                    alt="user-image"
                                    class="me-1"
                                    height="12"
                                    src="@/assets/images/flags/us.jpg"
                            />
                            <span class="align-middle">English</span>
                        </a>
                        <!-- item-->
                        <a
                                class="dropdown-item notify-item language"
                                data-lang="hun"
                                href="javascript:void(0);"
                                @click="selectLocale('hu')"
                        >
                            <img
                                    alt="user-image"
                                    class="me-1"
                                    height="12"
                                    src="@/assets/images/flags/hungary.jpg"
                            />
                            <span class="align-middle">Hungarian</span>
                        </a>
                    </div>
                </div>

                <div v-if="false" class="dropdown d-none d-lg-inline-block">
                    <button
                            aria-expanded="false"
                            aria-haspopup="true"
                            class="btn header-item noti-icon"
                            data-bs-toggle="dropdown"
                            type="button"
                    >
                        <eva-icon class="icon-sm" name="grid-outline"></eva-icon>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
                        <div class="p-3">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h5 class="m-0 font-size-15">Web Apps</h5>
                                </div>
                                <div class="col-auto">
                                    <a
                                            class="small fw-semibold text-decoration-underline"
                                            href="#!"
                                    >
                                        View All</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="px-lg-2 pb-2">
                            <div class="row g-0">
                                <div class="col">
                                    <a class="dropdown-icon-item" href="#!">
                                        <img alt="Github" src="@/assets/images/brands/github.png"/>
                                        <span>GitHub</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="#!">
                                        <img
                                                alt="bitbucket"
                                                src="@/assets/images/brands/bitbucket.png"
                                        />
                                        <span>Bitbucket</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="#!">
                                        <img
                                                alt="dribbble"
                                                src="@/assets/images/brands/dribbble.png"
                                        />
                                        <span>Dribbble</span>
                                    </a>
                                </div>
                            </div>

                            <div class="row g-0">
                                <div class="col">
                                    <a class="dropdown-icon-item" href="#!">
                                        <img
                                                alt="dropbox"
                                                src="@/assets/images/brands/dropbox.png"
                                        />
                                        <span>Dropbox</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="#!">
                                        <img
                                                alt="mail_chimp"
                                                src="@/assets/images/brands/mail_chimp.png"
                                        />
                                        <span>Mail Chimp</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="#!">
                                        <img alt="slack" src="@/assets/images/brands/slack.png"/>
                                        <span>Slack</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="dropdown d-inline-block">
                    <button
                            id="page-header-notifications-dropdown-v"
                            aria-expanded="true"
                            aria-haspopup="true"
                            class="btn header-item noti-icon"
                            type="button"
                            data-bs-toggle="dropdown"
                            ref="noti-btn"
                    >
                        <i class="mdi mdi-bell-alert" :class="{'mdi-bell-alert text-warning': !!unread, 'mdi-bell-sleep text-muted': !unread}"></i>
                        <span v-if="!!unread" class="noti-dot bg-danger rounded-pill">{{ unread }}</span>
                    </button>


                    <div
                        aria-labelledby="page-header-notifications-dropdown-v"
                        class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                        @click.stop
                        ref="noti-list"
                    >
                        <b-overlay :show="notificationLoading">
                            <div class="p-3">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h5 class="m-0 font-size-15">{{ $t('notifications::notifications.notifications').ucFirst() }}</h5>
                                    </div>
                                    <div v-if="!allRead" class="col-auto">
                                        <a
                                            class="small fw-semibold text-decoration-underline cursor-pointer"
                                            @click.prevent="markAsReadAll"
                                        >
                                            {{ $t('notifications::notifications.mark_as_read_all').ucFirst() }}</a
                                        >
                                    </div>
                                </div>
                            </div>
                            <SimpleBar data-simplebar style="max-height: 250px">
                                <div v-for="(notification, index) in notifications" :key="index" class="text-reset notification-item"
                                     :class="{'cursor-pointer': !notification.read_at}"
                                     @click.prevent="markAsRead(notification)"
                                >
                                    <div class="d-flex">
                                        <div class="flex-shrink-0 avatar-sm me-3">
                                        <span
                                            class="
                                            avatar-title
                                            bg-soft-warning
                                            rounded-circle
                                            font-size-16
                                          "
                                        >
                                          <i class="mdi mdi-car-brake-alert text-warning"></i>
                                        </span>
                                        </div>
                                        <div class="flex-grow-1">
                                            <h6 class="mb-1" :class="{'text-danger': !notification.read_at}">{{ notification.title }}</h6>
                                            <div class="font-size-13 text-muted">
                                                <p class="mb-1">
                                                    {{ notification.message }}
                                                </p>
                                                <p class="mb-0">
                                                    <small>
                                                        <i class="mdi mdi-clock-outline me-1"></i>
                                                        <span>{{ notification.created_at?.printDate() }}</span>
                                                    </small>
                                                    <template v-if="notification.reference && notification.reference?.type === 'Appon\\Projects\\Models\\Project'">
                                                        <br/>
                                                        <router-link :to="$t('routes./projects/accessible-projects/:id(\\d+)', {id: notification.reference?.id}).rmValidation()" @click="closeNotiList">
                                                            <small>
                                                                <i class="bx bx-link-external me-1"></i>
                                                                <span>{{ notification.reference?.name }}</span>
                                                            </small>
                                                        </router-link>
                                                    </template>
                                                    <template v-else-if="notification.partner?.id">
                                                        <br/>
                                                        <router-link :to="$t('routes./partners/:id(\\d+)/:type?', {id: notification.partner.id}).rmValidation()" @click="closeNotiList">
                                                            <small>
                                                                <i class="bx bx-link-external me-1"></i>
                                                                <span>{{ notification.partner.name }}</span>
                                                            </small>
                                                        </router-link>
                                                    </template>
                                                    <template v-if="notification.subordinate?.id">
                                                        <br/>
                                                        <router-link :to="$t('routes./profiles/:id(\\d+)', {id: notification.subordinate.id}).rmValidation()" @click="closeNotiList">
                                                            <small>
                                                                <i class="bx bx-link-external me-1"></i>
                                                                <span>{{ notification.subordinate.name }}</span>
                                                            </small>
                                                        </router-link>
                                                    </template>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SimpleBar>
                            <div class="p-2 border-top d-grid">
                                <a
                                    v-if="!allLoaded"
                                    class="btn btn-sm btn-link font-size-14 btn-block text-center"
                                    href="javascript:void(0)"
                                    @click="more"
                                >
                                    <i class="uil-arrow-circle-right me-1"></i>
                                    <span>{{ $t('notifications::notifications.more').ucFirst() }}</span>
                                </a>
                            </div>
                        </b-overlay>
                    </div>
                </div>

                <div v-if="false" class="dropdown d-inline-block">
                    <button
                            id="right-bar-toggle-v"
                            class="btn header-item noti-icon right-bar-toggle toggle-right"
                            type="button"
                            @click="toggleRightSidebar"
                    >
                        <eva-icon class="icon-sm toggle-right" name="settings-outline"></eva-icon>
                    </button>
                </div>

                <div  class="dropdown d-inline-block">
                    <button
                            id="page-header-user-dropdown-v"
                            aria-expanded="false"
                            aria-haspopup="true"
                            class="btn header-item user text-start d-flex align-items-center"
                            data-bs-toggle="dropdown"
                            type="button"
                    >
                        <img
                                v-if="authenticated.image"
                                :src="authenticated.image"
                                :alt="authenticated.name"
                                class="rounded-circle header-profile-user"
                        />
                        <div v-else class="avatar-sm">
                            <span class="avatar-title rounded-circle bg-primary text-white font-size-16"> {{ letter }} </span>
                        </div>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end pt-0">
                        <div class="p-3 border-bottom">
                            <h6 class="mb-0">{{ authenticated.name }}</h6>
                            <p class="mb-0 font-size-11 text-muted">
                                {{ authenticated.email }}
                            </p>
                        </div>
                        <router-link class="dropdown-item" :to="$t('routes./profiles/:id(\\d+)', {id: authenticated?.id}).rmValidation()"
                        ><i
                                class="
                                  mdi mdi-account-circle
                                  text-muted
                                  font-size-16
                                  align-middle
                                  me-1
                                "
                        ></i>

                            <span class="align-middle">{{ $t('menu_points.profile').ucFirst() }}</span></router-link>
                        <router-link v-if="authenticated?.has_subordinates" class="dropdown-item" :to="$t('routes./subordinates')"
                        ><i
                            class="
                                  mdi mdi-account-group
                                  text-muted
                                  font-size-16
                                  align-middle
                                  me-1
                                "
                        ></i>

                            <span class="align-middle">{{ $t('users.subordinates').ucFirst() }}</span></router-link>
                        <router-link class="dropdown-item" to="/auth/logout"
                        ><i
                                class="mdi mdi-logout text-muted font-size-16 align-middle me-1"
                        ></i>
                            <span class="align-middle">{{ $t('menu_points.logout').ucFirst() }}</span></router-link
                        >
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
