<script>
// import MetisMenu from "metismenujs/dist/metismenujs";
import {mapGetters} from "vuex";
import MenuPoint from "./elements/menu-point.vue";

/**
 * Sidenav component
 */
export default {
    components: {MenuPoint},

    props: {
        onlyIcons: {
            type: Boolean
        }
    },

    computed: {
        ...mapGetters({
            menuItems: 'menuPoint/arranged',
            loading: 'menuPoint/arrangedLoading',
            user: 'user/authenticated'
        }),

        list: function () {
            return this.menuItems.filter(menuItem => {
                return this.show(menuItem.roles)
            })
        },
    },

    data() {
        return {
            menuData: null,
        };
    },

    methods: {
        show: function (roles) {

            if (!roles || !roles.length) {
                return true
            }

            return roles.every(role => {
                return this.can(role.key)
            });

        },
    },

    created() {
        this.$store.dispatch('menuPoint/arranged')
    },
};
</script>

<template>
    <!-- ========== Left Sidebar Start ========== -->

    <!--- Sidemenu -->
    <div id="sidebar-menu" :key="user.id">
        <!-- Left Menu Start -->
        <b-overlay variant="primary" :show="loading">
            <ul id="side-menu" class="metismenu list-unstyled" v-if="list && list.length">
                <template v-for="(item, index) in list" :key="index">
                    <li class="menu-title">
                        {{ item.translation_key ? $t(item.translation_key).ucFirst() : item.title.ucFirst() }}
                    </li>
                    <menu-point v-for="(item, index) in item.menu_points" :key="index" :sub="item">

                    </menu-point>
                </template>
            </ul>
            <div v-else class="skeleton"></div>
        </b-overlay>
    </div>
    <!-- Sidebar -->
</template>

<style>
    .skeleton {
        min-height: 50vh;
    }
</style>
